import { baseClient } from "../utils/request"

/**
 * 获取问卷列表
 * @param id 问卷id
 * @returns
 */
export const getQuestionList = (params: { qid: string; user_id: number }) =>
  baseClient.get<
    API.ApiResponse<{ list: API.Question[]; record_data: Record<string, any> }>
  >("/open/question", {
    params,
  })

/**
 * 查询问卷描述
 * @param id 问卷id
 * @returns
 */
export const getQuestionDesc = (id: string) =>
  baseClient.get<API.ApiResponse<API.QuestionDesc>>("/open/questionnaire", {
    params: { qid: id },
  })

/**
 * 乡镇地区几口
 * @param id 问卷id
 * @returns
 */
export const getQuestionArea = (id: string) =>
  baseClient.get<API.TableData<API.QuestionArea>>("/open/area", {
    params: { qid: id },
  })
/**
 * 提交最后一题
 * @param id 问卷id
 * @returns
 */
export const putQuestionLast = (id: number, personId: string) =>
  baseClient.put("/open/question", {
    data: { questionnaire_id: id, user_id: personId },
  })

export type GetUserParams = {
  questionnaire_id: number
  area_id: string
  name?: string
}
export const getUserList = (params: GetUserParams) =>
  baseClient.get<API.ApiResponse<{ list: API.User[] }>>(
    "/open/questionnaireUser",
    {
      params,
    }
  )

export type SubmitQuestionParams = {
  question_id: number
  /**
   * 问卷ID
   */
  questionnaire_id: number
  user_id: number
  /**
   * 问答题内容，问答题时必传
   */
  content?: string
  option_list?: {
    /**
     * 问答题回答内容，选项关联弹窗值 1,2,3
     */
    option_data?: string
    /**
     * 选项关联其他值
     */
    other_content?: string
    /**
     * 选项ID
     */
    question_option_id: string
  }[]
}
export const submitQuestion = (data: SubmitQuestionParams) =>
  baseClient.post("/open/question", { data })
