import type { RequestOptionsInit, ResponseError } from "umi-request"
import { Toast } from "antd-mobile"
import request, { extend } from "umi-request"

/**
 * 异常处理程序
 */
const errorHandler = (error: ResponseError) => {
  const { message } = error
  Toast.show({
    icon: "fail",
    content: message || "服务异常",
  })

  return Promise.reject(error)
}

const createClient = (baseUrl: string) => {
  return extend({
    errorHandler,
    credentials: "include",
    timeout: 600000,
    prefix: baseUrl,
  })
}

/**
 * 请求拦截
 */
request.interceptors.request.use((url: string, options: RequestOptionsInit) => {
  return {
    url,
    options: {
      ...options,
      headers: Object.assign(options.headers || {}),
    },
  }
})

/**
 * 响应拦截
 */
request.interceptors.response.use(async (response: Response) => {
  if (response.status === 200) {
    const data = await response.clone().json()
    const { code, message } = data
    if (code === 0) {
      return response
    }
    throw new Error(message)
  }
  throw new Error(response.statusText)
})

// const baseClient = createClient('http://homeland.test.guohualinks.com/persons_api')

// const baseClient = createClient('http://10.20.28.116:6200')
const baseClient = createClient("https://homeland.guohualinks.com/persons_api")

const apiClient = createClient("/api")

export { baseClient, apiClient }
