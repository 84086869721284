import {
	Button,
	Card,
	Cascader,
	ErrorBlock,
	Form,
	Input,
	Selector,
	Space,
	Toast,
} from 'antd-mobile'
import { useQuestionArea, useQuestionDesc, useUserList } from './hook'
import options from './data.json'
import styles from './index.module.css'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { LocationFill } from 'antd-mobile-icons'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { IsQuestionCompany } from '../../utils/const'

const Home = () => {
	const [form] = Form.useForm()
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const [visible, setVisible] = useState(false)
	const [userId, setUserId] = useState<number | null>(null)
	const [searchName, setSearchName] = useState('')

	const qid = (searchParams.get('qid') as string) || '3e3c72a455dd4e6e806f1d9128af475f'

	const { data: questionArea } = useQuestionArea(qid)

	const { data: questionDesc } = useQuestionDesc(qid)
	const { data: user, run } = useUserList()

	const slectedUser = userId ? user?.data.list.find((u) => u.user_id === userId) : null

	// 是否需要答两套题
	// const hasTwoQuestion =
	// 	slectedUser && questionDesc?.data.questionnaire_id === 1
	// 		? (slectedUser.gender === 1 && slectedUser.age >= 16 && slectedUser.age <= 59) ||
	// 		(slectedUser.gender === 2 && slectedUser.age >= 16 && slectedUser.age <= 54)
	// 		: false

	const formatUserOptions = useCallback(
		(keywords?: string) => {
			const userList = keywords
				? user?.data.list.filter((item) => item.name.includes(keywords || ''))
				: user?.data.list
			return (
				userList?.map((item) => ({
					label: `${item.name} ${item.is_same ? `${item.age}岁` : ''}`,
					value: item.user_id,
					description: item.is_success === 1 ? '进行中' : item.is_success === 2 ? '已完成' : '',
				})) || []
			)
		},
		[user?.data]
	)

	const userOptions = useMemo(() => formatUserOptions(searchName), [formatUserOptions, searchName])

	const handleGotoQuestionPage = () => {
		navigate(`/question?qid=${qid}&uid=${userId}`)
	}

	const handleSearch = () => {
		const keywords = form.getFieldValue('keywords').trim()
		setSearchName(keywords || '')
		if (!keywords) {
			Toast.show('请输入姓名')
		} else if (keywords.length < 2) {
			Toast.show('请输入至少两个字')
		}
	}

	useEffect(() => {
		if (userOptions.length === 1 && searchName) {
			setUserId(userOptions[0].value)
		} else {
			setUserId(null)
		}
	}, [userOptions, searchName])

	useEffect(() => {
		if (questionDesc?.data?.questionnaire_name) {
			document.title = questionDesc.data.questionnaire_name
			localStorage.setItem('area_name', questionDesc.data.questionnaire_name)
			// 企业
			if (questionDesc.data.user_id) {
				setUserId(questionDesc.data.user_id)
			}
		}
	}, [questionDesc?.data])

	return (
		<div className={styles.home}>
			<Space direction='vertical' style={{ '--gap': '18px' }} block>
				<Card
					headerStyle={{
						justifyContent: 'center',
					}}
					title={<span style={{ fontSize: '20px' }}>{questionDesc?.data?.questionnaire_name}</span>}
				>
					<div style={{ fontSize: '18px', textIndent: 38 }}>
						{questionDesc?.data?.questionnaire_content}
					</div>
				</Card>

				{IsQuestionCompany(qid) &&
					<>
						<Card>
							<Button
								color='primary'
								fill='solid'
								onClick={() => {
									setVisible(true)
								}}
							>
								请选择乡镇/街道
							</Button>
							<Cascader
								options={questionArea?.data.list as any}
								visible={visible}
								onClose={() => {
									setVisible(false)
								}}

								onConfirm={(value) => {
									if (value && value.length === 2) {
										run({
											questionnaire_id: questionDesc?.data.questionnaire_id as number,
											area_id: value[1],
										})
									}
								}}
							>
								{(items) => {
									if (items.every((item) => item === null)) {
										return <span className={styles.location}>未选择</span>
									} else {
										return (
											<span className={styles.location}>
												<LocationFill />
												{items.map((item) => item?.label ?? '未选择').join('-')}
											</span>
										)
									}
								}}
							</Cascader>
						</Card>
						<Card>
							{!user || user?.data.list.length === 0 ? (
								<ErrorBlock
									status='default'
									title='暂无人员列表'
									description='请选择社区或者更换一个乡镇/街道试试'
								/>
							) : (
								<div className={styles.usersWapper}>
									<div className={styles.userSearch}>
										<Form form={form} layout='horizontal'>
											<Form.Item
												name='keywords'
												extra={
													<Button color='primary' fill='none' onClick={handleSearch}>
														搜索
													</Button>
												}
											>
												<Input placeholder='请输入姓名' clearable onClear={() => setSearchName('')} />
											</Form.Item>
										</Form>
									</div>
									{searchName.length >= 2 && userOptions.length > 0 && (
										<div className={styles.userList}>
											<Selector
												value={userId ? [userId] : []}
												options={userOptions}
												onChange={(arr, extend) => setUserId(arr[0])}
											/>
										</div>
									)}
									{((searchName.length >= 2 && userOptions.length === 0) || searchName.length < 2) && (
										<ErrorBlock
											status='empty'
											title='暂无匹配到人员'
											description='请输入名称进行搜索，至少两个字'
										/>
									)}
								</div>
							)}
						</Card>
					</>
				}



				<Button color='primary' className={!IsQuestionCompany(qid) ? styles.btnTop : ''} block disabled={!userId} onClick={handleGotoQuestionPage}>
					立即填写
				</Button>

				{/* <div style={{ textAlign: 'center', marginTop: '40px', }}><a style={{ color: 'blue' }}>问卷说明</a></div> */}
			</Space>
		</div>
	)
}

export default Home
