import { Routes, Route } from 'react-router-dom'
import Question from './pages/Question'
import Home from './pages/Home'
import Success from './pages/Success'
import './App.css'
function App() {
	return (
		<div className='App'>
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/question' element={<Question />} />
				<Route path='/success' element={<Success />} />
			</Routes>
		</div>
	)
}

export default App
