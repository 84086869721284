import React, { FC } from 'react'

import styles from './index.module.css'

interface QuestionCardProps {
	index: string
	title: React.ReactNode
	extra?: React.ReactNode
  children?: React.ReactNode
}

const QuestionCard: FC<QuestionCardProps> = (props) => {
	const { index, title, extra, children } = props
	return (
		<div className={styles.questionCard}>
			<span className={styles.index}>{index}</span>
			<div className={styles.header}>
				<div className={styles.title}>{title}</div>
				<div className={styles.extra}>{extra}</div>
			</div>
      <div className={styles.content}>{children}</div>
		</div>
	)
}

export default QuestionCard
