export const QuestionTyping: Record<API.QuestionType, string> = {
  single: "单选题",
  multiple: "多选题",
  text: "",
  number: "",
}

export const QuestionCompanyId = (): string => {
  return "f9b36a9ed5964e2da4b5c3abead184d7"
}

export const IsQuestionCompany = (question_id: string): boolean => {
  return question_id !== QuestionCompanyId()
}
