import { ProgressBar } from 'antd-mobile'
import { FC } from 'react'

interface ProgressProps {
	total: number
	current: number
	textWidth?: string
	trackWidth?: string
}

const Progress: FC<ProgressProps> = (props) => {
	const { total, current, trackWidth = '6px', textWidth = '28px' } = props
	const percent = !total ? 0 : (current / total) * 100
	return (
		<ProgressBar
			percent={percent}
			text={`${current}/${total}`}
			style={{ '--text-width': textWidth, '--track-width': trackWidth }}
		/>
	)
}

export default Progress
