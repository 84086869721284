import {
	Button,
	Checkbox,
	Dialog,
	Form,
	Input,
	Modal,
	Radio,
	Space,
	Tag,
	TextArea,
	Toast,
} from 'antd-mobile'
import { FC, useState, useEffect, useMemo, Fragment } from 'react'
import Progress from '../../components/Progress'
import QuestionCard from '../../components/QuestionCard'
import styles from './index.module.css'
import { useQuestionList, useSubmitQuestion } from './hook'
import { QuestionTyping } from '../../utils/const'
import { SubmitQuestionParams, putQuestionLast } from '../../api'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { ReactComponent as CheckSvg } from '../../assetss/svg/check-svg.svg';
import { ReactComponent as UnCheckSvg } from '../../assetss/svg/uncheck-svg.svg';


const jobId = 'fc4ffff0b83a41f8b5b08d8888c76e78'

const Question: FC = () => {
	const [form] = Form.useForm()
	const [modalForm] = Form.useForm()
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const [questions, setQuestions] = useState<API.Question[]>([])
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
	const [finishedQuestionIndex, setFinishedQuestionIndex] = useState(0)
	const [historyFormValues, setHistoryFormValues] = useState<Record<string, any> | null>(null)
	const [extraQuestionOption, setExtraQuestionOption] = useState<{
		visible: boolean
		required: boolean
		title: string
		question_option_uuid: string
		option_data_type: API.QuestionType
		option_data_list: API.ExtraOptionItem[]
	}>({
		visible: false,
		required: false,
		title: '',
		question_option_uuid: '',
		option_data_type: 'single',
		option_data_list: [],
	})
	const qid = (searchParams.get('qid') as string) || '3e3c72a455dd4e6e806f1d9128af475f'
	const oid = (searchParams.get('oid') as string) || '3e3c72a455dd4e6e806f1d9128af475f'
	const uid = searchParams.get('uid') as any
	// const hasTwoQuestion = searchParams.get('hasTwoQuestion') as string
	const { data } = useQuestionList({ qid, user_id: uid })
	const { run, loading } = useSubmitQuestion()

	// 被关联的题目
	const relQuestions = useMemo(
		() =>
			data?.data && data.data.list ? data.data.list.filter((item) => item.is_rel_option === 1) : [],
		[data?.data]
	)

	/**
	 * 找出需要显示的被关联的题目
	 * @param optionId 选项id
	 */
	const handleInsertRelQuestion = (optionId: string, newQuestionList: API.Question[]) => {
		const currentQuestion = newQuestionList[currentQuestionIndex]

		const targetOption = currentQuestion.option_list.find(
			(item) => item.question_option_uuid === optionId
		)

		if (targetOption && targetOption.rel_question_ids) {
			const relQuestionIds = targetOption.rel_question_ids.split(',').map((id) => Number(id))
			const relQuestionList = relQuestions.filter((item) =>
				relQuestionIds.includes(item.question_id)
			)
			const newQuestions = [...newQuestionList]
			newQuestions.splice(currentQuestionIndex + 1, 0, ...relQuestionList)

			return newQuestions
		}
		return newQuestionList
	}

	/**
	 * 如果有 移除被历史关联的题目
	 * @param optionId 选项id
	 */
	const handleRemoveRelQuestion = (optionId: string) => {
		const currentQuestion = questions[currentQuestionIndex]

		const targetOption = currentQuestion.option_list.find(
			(item) => item.question_option_uuid === optionId
		)

		if (targetOption && targetOption.rel_question_ids) {
			const relQuestionIds = targetOption.rel_question_ids.split(',').map((id) => Number(id))
			const newQuestions = questions.filter((item) => !relQuestionIds.includes(item.question_id))
			return newQuestions
		}
		return questions
	}

	// const handleModalFormValueChange = (
	// 	changedValue: Record<string, any>,
	// 	values: Record<string, any>
	// ) => {}

	const handleDialogFormSubmit = () => {
		modalForm
			.validateFields()
			.then((res) => {
				// 自动选择
				const currentQuestion = questions[currentQuestionIndex]
				const formValues = form.getFieldsValue(true)
				const currentValue = formValues[currentQuestion.question_uuid] || []

				if (currentQuestion.question_type === 'multiple') {
					if (
						!currentValue ||
						(Array.isArray(currentValue) &&
							!currentValue.includes(extraQuestionOption.question_option_uuid))
					) {
						form.setFieldsValue({
							[currentQuestion.question_uuid]: [
								...currentValue,
								extraQuestionOption.question_option_uuid,
							],
						})
					}
				} else if (currentQuestion.question_type === 'single') {
					if (!currentValue || currentValue !== extraQuestionOption.question_option_uuid) {
						form.setFieldsValue({
							[currentQuestion.question_uuid]: extraQuestionOption.question_option_uuid,
						})
					}
				}

				setExtraQuestionOption({
					...extraQuestionOption,
					visible: false,
				})
			})
			.catch((error) => {
				Toast.show({
					content: '请选择',
				})
			})
	}

	const handleDialogFormCancel = () => {
		setExtraQuestionOption({
			...extraQuestionOption,
			visible: false,
		})
	}

	/**
	 * 判断该选项是否需要展示弹窗 显示题目
	 * @param optionId 选项id
	 */
	const handleShowExtraModalQuestion = (optionId: string) => {
		const currentQuestion = questions[currentQuestionIndex]

		const targetOption = currentQuestion.option_list.find(
			(item) => item.question_option_uuid === optionId
		)
		if (targetOption?.option_data_type) {
			setExtraQuestionOption({
				visible: true,
				required: targetOption.other_required === 1,
				title: `${targetOption.question_option_name}（${targetOption.option_data_type === 'multiple' ? '多选' : '单选'
					}）`,
				question_option_uuid: targetOption.question_option_uuid,
				option_data_type: targetOption.option_data_type,
				option_data_list: targetOption.option_data_list,
			})
		} else {
			setExtraQuestionOption({
				visible: false,
				required: false,
				title: '',
				question_option_uuid: '',
				option_data_type: 'single',
				option_data_list: [],
			})
		}
	}

	const handlePreviewModalForm = (optionId: string) => {
		modalForm.setFieldsValue(modalForm.getFieldsValue(true))
		handleShowExtraModalQuestion(optionId)
	}

	const handleChecboxChange = (
		checked: boolean,
		questionId: string,
		option: { label: string; value: any } & Partial<API.QuestionOption>
	) => {
		if (checked) {
			if (option.option_data_type) {
				handleShowExtraModalQuestion(option.value)
			}
		} else {
			modalForm.setFieldsValue({ [option.question_option_uuid as string]: undefined })
		}
	}

	const renderFormItem = (
		question: API.Question,
		options: ({ label: string; value: any } & Partial<API.QuestionOption>)[],
		required?: boolean
	) => {
		const { question_type: questionType, question_uuid: name, max_num } = question
		if (questionType === 'single') {
			return (
				<Form.Item shouldUpdate noStyle>
					{() => {
						const values = form.getFieldsValue()
						const checkedValue: any = values[name]
						return (
							<Form.Item name={name} rules={[{ required, message: '请选择' }]}>
								<Radio.Group>
									<Space direction='vertical' style={{ '--gap-vertical': '18px' }}>
										{options.map((opt) => (
											<Fragment key={opt.value}>
												<Radio
													value={opt.value}
													onChange={(checked) =>
														handleChecboxChange(checked, question.question_uuid as string, opt)
													}
												>
													{opt.label}{' '}
													{!!opt.option_data_type ? (
														<Button
															size='mini'
															color='primary'
															fill='none'
															onClick={() =>
																handlePreviewModalForm(opt.question_option_uuid as string)
															}
														>
															查看
														</Button>
													) : (
														''
													)}
												</Radio>
												{checkedValue === opt.value && opt.is_other === 1 && (
													<Form.Item
														name={opt.value}
														rules={[{ required: opt.other_required === 1 }]}
													>
														<TextArea placeholder='请输入' />
													</Form.Item>
												)}
											</Fragment>
										))}
									</Space>
								</Radio.Group>
							</Form.Item>
						)
					}}
				</Form.Item>
			)
		}
		if (questionType === 'multiple') {
			return (
				<Form.Item shouldUpdate>
					{() => {
						const values = form.getFieldsValue()
						const checkedValues: any = values[name] || []
						const isLimitCheckCount = max_num > 0 && checkedValues.length >= max_num

						// 互斥的情况
						const disabledMutex = (ids: string[]) => {
							if (ids.length === 0 || checkedValues.length === 0) {
								return false
							}
							return checkedValues.some((id: string) => ids.includes(id))
						}

						const checkOne = () => {

							if (checkedValues.length > 0) {

								return checkedValues[0];
							}
							return undefined
						}
						const checkTwo = () => {
							if (checkedValues.length > 1) {
								return checkedValues[1];
							}
							return undefined
						}
						const checkThree = () => {
							if (checkedValues.length > 2) {
								return checkedValues[2];
							}
							return undefined
						}
						return (
							<Form.Item name={name} rules={[{ required, message: '请选择' }]} noStyle>
								<Checkbox.Group>
									<Space direction='vertical' style={{ '--gap-vertical': '18px' }}>
										{options.map((opt) => (
											<Fragment key={opt.value}>
												<Checkbox
													value={opt.value}
													icon={check => check ? (<CheckSvg width={24} height={24}></CheckSvg>) : (<UnCheckSvg width={24} height={24}></UnCheckSvg>)}
													disabled={
														(isLimitCheckCount && !checkedValues.includes(opt.value)) ||
														disabledMutex(opt.disable_option || [])
													}
													onChange={(checked) =>
														handleChecboxChange(checked, question.question_uuid as string, opt)
													}
												>
													{opt.label}
													{opt.is_order === 1 && <>
														{checkOne() && checkOne() === opt.question_option_uuid && <Tag color='danger'>优选</Tag>}
														{checkTwo() && checkTwo() === opt.question_option_uuid && <Tag color='success'>中等</Tag>}
														{checkThree() && checkThree() === opt.question_option_uuid && <Tag color='warning'>一般</Tag>}
													</>}


													{!!opt.option_data_type ? (
														<Button
															size='mini'
															color='primary'
															fill='none'
															onClick={() =>
																handlePreviewModalForm(opt.question_option_uuid as string)
															}
														>
															查看
														</Button>
													) : (
														''
													)}
												</Checkbox>
												{checkedValues.includes(opt.value) && opt.is_other === 1 && (
													<Form.Item
														name={opt.value}
														rules={[{ required: opt.other_required === 1 }]}
													>
														<TextArea placeholder='请输入' />
													</Form.Item>
												)}
											</Fragment>
										))}
									</Space>
								</Checkbox.Group>
							</Form.Item>
						)
					}}
				</Form.Item>
			)
		}
		if (questionType === 'number') {
			return options.map((opt) => (
				<Form.Item
					name={opt.question_option_uuid}
					extra={opt.question_option_name}
					key={opt.question_option_uuid}
					rules={[{ required }]}
				>
					<Input type='number' min={opt.min_num} max={opt.max_num} placeholder={opt.placehoder} />
				</Form.Item>
			))
		}
		if (questionType === 'text') {
			return (
				<Form.Item name={name} rules={[{ required }]}>
					<TextArea placeholder='请输入内容' />
				</Form.Item>
			)
		}
		return null
	}

	const handleSubmitQuestion = async (currentQuestion: API.Question, answer: any) => {
		const params: SubmitQuestionParams = {
			questionnaire_id: currentQuestion.questionnaire_id,
			user_id: uid,
			question_id: currentQuestion.question_id,
		}
		const modalFormValues = modalForm.getFieldsValue(true)
		const formValues = form.getFieldsValue(true)
		const options = currentQuestion.option_list
		if (currentQuestion.question_type === 'text') {
			params.content = answer
		} else if (currentQuestion.question_type === 'single') {
			const target = options.find((opt) => opt.question_option_uuid === answer)
			const param = { question_option_id: answer, option_data: '', other_content: '' }
			if (target && target.option_data_type) {
				param.option_data = modalFormValues[uid]?.join(',')
			}
			if (target && target.is_other === 1) {
				if (target.other_required === 1 && !formValues[answer]) {
					throw new Error('请输入');
				} else {
					param.other_content = formValues[answer]
				}

			}
			params.option_list = [param]
		} else if (currentQuestion.question_type === 'multiple' && Array.isArray(answer)) {
			params.option_list = (answer as string[]).map((uid: string) => {
				const target = options.find((opt) => opt.question_option_uuid === uid)
				const params = { question_option_id: uid, option_data: '', other_content: '' }
				if (target && target.option_data_type) {
					params.option_data = modalFormValues[uid]?.join(',')
				}
				if (target && target.is_other === 1) {
					if (target.other_required === 1 && !formValues[uid]) {
						throw new Error('请输入');
					} else {
						params.other_content = formValues[uid]
					}
				}
				return params
			})
		} else if (currentQuestion.question_type === 'number' && Array.isArray(answer)) {
			params.option_list = options.map((opt, index) => {
				return { question_option_id: opt.question_option_uuid, other_content: answer[index] }
			})
		}
		await run(params)
	}

	const handleNextQuestion = async () => {
		try {

			// form.validateFields().then(async (res) => {


			const currentQuestion = questions[currentQuestionIndex]
			// 拿到最新的答案
			const values = form.getFieldsValue(true)
			// 当前的答案
			let value = values[currentQuestion.question_uuid]

			if (currentQuestion.question_type === 'number') {
				const inputValues = currentQuestion.option_list.map(
					(item) => values[item.question_option_uuid]
				)
				value = inputValues
			}


			console.log(value, values, "----")
			if (!value || value.length === 0) {
				Toast.show({
					content: '该题不能为空',
					position: 'top',
				})
				return
			}

			await handleSubmitQuestion(currentQuestion, value).catch((error) => {
				console.log(error,"---")
				Toast.show({
					content: '请输入',
					position: 'top',
				})
				throw new Error('请输入');
			})

			let newQuestions = [...questions]

			if (currentQuestion.question_type === 'single') {
				// 和历史答案比较是否相同
				if (!historyFormValues || historyFormValues[currentQuestion.question_uuid] !== value) {
					/**
					 * 不相同
					 * 1. 看历史答案是否有新插入的题目
					 * ···如果有 则需要先删除
					 * 2. 在看新答案是否需要插入新题目
					 */
					if (historyFormValues && historyFormValues[currentQuestion.question_uuid]) {
						newQuestions = handleRemoveRelQuestion(historyFormValues[currentQuestion.question_uuid])
					}
					// 判断是否需要根据当前所选 插入对应的题目
					newQuestions = handleInsertRelQuestion(value, [...newQuestions])
				}
			} else if (currentQuestion.question_type === 'multiple') {
				if (
					!historyFormValues ||
					historyFormValues[currentQuestion.question_uuid]?.[0] !== value[0]
				) {
					/**
					 * 不相同
					 * 1. 看历史答案是否有新插入的题目
					 * ···如果有 则需要先删除
					 * 2. 在看新答案是否需要插入新题目
					 */
					if (historyFormValues && historyFormValues[currentQuestion.question_uuid]) {
						newQuestions = handleRemoveRelQuestion(
							historyFormValues[currentQuestion.question_uuid]?.[0]
						)
					}
					// 判断是否需要根据当前所选 插入对应的题目
					newQuestions = handleInsertRelQuestion(value[0], [...newQuestions])
				}
			}
			setQuestions(newQuestions)
			if (finishedQuestionIndex === currentQuestionIndex) {
				setFinishedQuestionIndex(finishedQuestionIndex + 1)
			}
			if (currentQuestionIndex === newQuestions.length - 1) {
				// if (hasTwoQuestion) {
				// 	Modal.show({
				// 		content: '即将跳转到【平谷区残疾人就业状况调查问卷】',
				// 		closeOnMaskClick: false,
				// 	})
				// 	const timerId = setTimeout(() => {
				// 		Modal.clear()
				// 		timerId && clearTimeout(timerId)
				// 		window.location.href = `${window.origin}/question?qid=${jobId}&uid=${uid}&oid=${oid}`
				// 	}, 3000)
				// } else {
				await putQuestionLast(currentQuestion.questionnaire_id, uid)

				navigate(`/success?qid=${qid}&oid=${oid}`)
				// }
			} else {
				setCurrentQuestionIndex(currentQuestionIndex + 1)
			}
			// }).catch((error) => {
			// 	console.log(error)
			// 	Toast.show({
			// 		content: error.errorFields[0].errors.toString(),
			// 	})
			// })
		} catch (error: any) {
			Toast.show({
				icon: 'fail',
				content: error.message || '服务异常',
			})
		}
	}

	const handlePrevQuestion = () => {
		if (currentQuestionIndex <= 0) {
			return
		}
		// 保存历史答案
		const values = form.getFieldsValue()
		setHistoryFormValues(values)
		setCurrentQuestionIndex(currentQuestionIndex - 1)
	}

	const renderFooter = () => {
		if (currentQuestionIndex <= questions.length - 1) {
			return (
				<div className={styles.footer}>
					<Button
						block
						size='large'
						disabled={currentQuestionIndex <= 0}
						onClick={handlePrevQuestion}
					>
						上一题
					</Button>
					<Button
						block
						color='primary'
						size='large'
						type='submit'
						loading={loading}
						disabled={currentQuestionIndex > questions.length - 1}
						onClick={handleNextQuestion}
					>
						{currentQuestionIndex < questions.length - 1 ? '下一题' : '提交问卷'}
					</Button>
				</div>
			)
		}
		return null
	}

	useEffect(() => {
		if (data?.data && data.data.list) {
			const normalQuestions = data.data.list.filter((item) => item.is_rel_option === 0)
			setQuestions(normalQuestions)
		} else {
			setQuestions([])
		}
	}, [data?.data])

	useEffect(() => {
		try {
			const areaName = localStorage.getItem('area_name')
			if (areaName) {
				document.title = areaName
			}
		} catch (error) { }
	}, [])

	useEffect(() => {
		if (data?.data.record_data) {
			form.setFieldsValue(data.data.record_data)
			modalForm.setFieldsValue(data.data.record_data)
		}
	}, [data?.data, modalForm, form])

	return (
		<div className={styles.question}>
			<div className={styles.header}>
				<Progress total={questions.length} current={finishedQuestionIndex} />
			</div>
			<div className={styles.content}>
				<Form
					form={form}
					preserve={false}
					layout='horizontal'
					initialValues={data?.data.record_data}
					// onValuesChange={handleValuesChange}
					footer={renderFooter()}
				>
					{questions.map((question, index) => (
						<div
							key={question.question_uuid}
							className={index === currentQuestionIndex ? styles.show : styles.hiden}
						>
							<QuestionCard
								index={index >= 9 ? `${index + 1}` : `0${index + 1}`}
								title={`${question.question_name} ${question.max_num > 0 ? `(最多选择 ${question.max_num} 项)` : ''
									}`}
								extra={
									<span
										style={{ color: question.question_type === 'single' ? '#ffb206' : '#00b578' }}
									>
										{QuestionTyping[question.question_type]}
									</span>
								}
							>
								{renderFormItem(
									question,
									question.option_list.map((item) => ({
										label: item.question_option_name,
										value: item.question_option_uuid,
										...item,
									}))
								)}
							</QuestionCard>
						</div>
					))}
				</Form>
			</div>
			<Dialog
				visible={extraQuestionOption.visible}
				title={extraQuestionOption.title}
				content={
					<Form
						form={modalForm}
						initialValues={data?.data.record_data}
						// onValuesChange={handleModalFormValueChange}
						onFinish={handleDialogFormSubmit}
					>
						{renderFormItem(
							{
								question_uuid: extraQuestionOption.question_option_uuid,
								question_type: extraQuestionOption.option_data_type,
							} as any,
							extraQuestionOption.option_data_list.map((item) => ({
								label: item.option_data_name,
								value: item.option_data_id,
								...item,
							})),
							extraQuestionOption.option_data_type === 'text' ? extraQuestionOption.required : true
						)}
					</Form>
				}
				destroyOnClose
				actions={[
					{ key: 'submit', text: '确 定', onClick: handleDialogFormSubmit },
					{ key: 'cancel', text: '关 闭', onClick: handleDialogFormCancel },
				]}
			></Dialog>
		</div>
	)
}

export default Question
