import { IsQuestionCompany } from '../../utils/const'
import { Button, Result } from 'antd-mobile'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

const Success = () => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const qid = searchParams.get('qid')

	useEffect(() => {
		try {
			const areaName = localStorage.getItem('area_name')
			if (areaName) {
				document.title = areaName
			}
		} catch (error) { }
	}, [])
	return (
		<Result
			status='success'
			title='问卷调查到此结束，再次感谢您的积极配合!'
			description={
				<Button
					color='primary'
					onClick={() => {
						navigate(`/?qid=${qid}`)
					}}
				>
					返回
				</Button>
			}
		/>
	)
}

export default Success
